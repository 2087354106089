const navbarItems = [
  ///////////menu 1
  {
    itemId: 1,
    itemName: "M Serisi",
    image: "/assets/images/menu/M.png",
    torqueRange: "M-SERIES-TORQUE",
    motorPower: "M-SERIES-MOTOR",
    speedRange: "M-SERIES-SPEED",
    categoryId: 201,
    link:"/urun/M",
    tableType : "tableStandard",
    type:"reducer"
  },
  {
    itemId: 2,
    itemName: "N Serisi",
    image:
    "/assets/images/menu/N.png",
    torqueRange: "N-SERIES-TORQUE",
    motorPower: "N-SERIES-MOTOR",
    speedRange: "N-SERIES-SPEED",
    categoryId: 202,
    link:"/urun/N",
    tableType : "tableStandard",
    type:"reducer"

  },
  {
    itemId: 3,
    itemName: "D Serisi",
    image: "/assets/images/menu/D.png",
    torqueRange: "D-SERIES-TORQUE",
    motorPower: "D-SERIES-MOTOR",
    speedRange: "D-SERIES-SPEED",
    categoryId: 203,
    link:"/urun/D",
    tableType : "tableStandard",
    type:"reducer"

  },
  {
    itemId: 4,
    itemName: "K Serisi",
    image:
    "/assets/images/menu/K.png",
    torqueRange:"K-SERIES-TORQUE",
    motorPower: "K-SERIES-MOTOR",
    speedRange: "K-SERIES-SPEED",
    categoryId: 204,
    link:"/urun/K",
    tableType : "tableStandard",
    type:"reducer"

  },

  {
    itemId: 5,
    itemName: "E Serisi",
    image: "/assets/images/menu/E.png",
    torqueRange: "E-SERIES-TORQUE",
    motorPower: "E-SERIES-MOTOR",
    speedRange: "E-SERIES-SPEED",
    categoryId: 205,
    link:"/urun/E",
    tableType : "tableStandard",
    type:"reducer"

  },

  {
    itemId: 101,
    itemName: "P.L Serisi",
    image: "/assets/images/menu/P.png",
    torqueRange: "P-SERIES-TORQUE",
    motorPower: "P-SERIES-MOTOR",
    speedRange: "P-SERIES-SPEED",
    categoryId: 301,
    type:"reducer",
    tableType : "tableStandard",
    link:"/urun/PL"
    
  },

  {
    itemId: 102,
    itemName: "P.K Serisi",
    image: "/assets/images/menu/PK.webp",
    torqueRange: "PK-SERIES-TORQUE",
    motorPower: "PK-SERIES-MOTOR",
    speedRange: "PK-SERIES-SPEED",
    categoryId: 301,
    type:"reducer",
    tableType : "tableStandard",
    link:"/urun/PK"
  },
  {
    itemId: 103,
    itemName: "R.L Serisi",
    image: "/assets/images/menu/R.png",
    torqueRange: "R-SERIES-TORQUE",
    motorPower: "R-SERIES-MOTOR",
    speedRange: "R-SERIES-SPEED",
    categoryId: 302,
    type:"reducer",
    tableType : "tableStandard",
    link:"/urun/RL"
  },
  {
    itemId: 104,
    itemName: "R.K Serisi",
    image: "/assets/images/menu/RK.webp",
    torqueRange: "RK-SERIES-TORQUE",
    motorPower: "RK-SERIES-MOTOR",
    speedRange: "RK-SERIES-SPEED",
    categoryId: 302,
    type:"reducer",
    tableType : "tableStandard",
    link:"/urun/RK"
  },
  {
    itemId: 105,
    itemName: "H Serisi",
    image: "/assets/images/menu/H.png",
    torqueRange: "H-SERIES-TORQUE",
    ratioRange: "H-SERIES-MOTOR",
    speedRange: "H-SERIES-SPEED",
    categoryId: 303,
    type:"reducer",
    tableType : "tableH",
    link:"/urun/H"
  },
  {
    itemId: 106,
    itemName: "B Serisi",
    image: "/assets/images/menu/B.png",
    torqueRange: "B-SERIES-TORQUE",
    ratioRange: "B-SERIES-MOTOR",
    speedRange: "B-SERIES-SPEED",
    categoryId: 304,
    type:"reducer",
    tableType : "tableB",

    link:"/urun/B"
  },
  {
    itemId: 201,
    itemName: "V Serisi",
    image: "/assets/images/menu/V.png",
    tonRange: "V-SERIES-TORQUE",
    ratioRange: "V-SERIES-MOTOR",
    motorPower: "V-SERIES-SPEED",
    categoryId: 401,
    type:"reducer",
    tableType : "tableV",

    link:"/urun/V"
  },
   {
    itemId: 219,
    itemName: "P.B Serisi",
    image: "/assets/images/menu/R.L.webp",
    torqueRange: "PB-SERIES-TORQUE",
    motorPower: "PB-SERIES-MOTOR",
    speedRange: "PB-SERIES-SPEED",
    categoryId: 401,
    type:"reducer",
    tableType : "tablePB",

    link:"/urun/PB"
  },
  {
    itemId: 202,
    itemName: "H.E Serisi",
    image: "/assets/images/menu/B-H.webp", 
    torqueRange: "HE-SERIES-TORQUE",
    ratioRange: "HE-SERIES-MOTOR",
    speedRange: "HE-SERIES-SPEED",
    categoryId: 402,
    type:"reducer",
    tableType : "tableHE",

    link:"/urun/HE"
  },

  {
    itemId: 203,
    itemName: "D.E Serisi",
    image: "/assets/images/menu/DRE.png", 
    torqueRange: "DE-SERIES-TORQUE",
    motorPower: "DE-SERIES-MOTOR",
    speedRange: "DE-SERIES-SPEED",
    categoryId: 402,
    type:"reducer",
    tableType : "tableStandard",

    link:"/urun/DE"
  },
  {
    itemId: 204,
    itemName: "K.E Serisi",
    image: "/assets/images/menu/KRE.webp", 
    torqueRange: "KE-SERIES-TORQUE",
    motorPower: "KE-SERIES-MOTOR",
    speedRange: "KE-SERIES-SPEED",
    categoryId: 402,
    type:"reducer",
    tableType : "tableStandard",

    link:"/urun/KE"
  },

  {
    itemId: 205,
    itemName: "D.B Serisi",
    image: "/assets/images/menu/DB.png", 
    torqueRange: "DB-SERIES-TORQUE",
    motorPower: "DB-SERIES-MOTOR",
    speedRange: "DB-SERIES-SPEED",
    categoryId: 403,
    type:"reducer",
    tableType : "tableStandard",

    link:"/urun/DB"
  },



  {
    itemId: 206,
    itemName: "K.B Serisi",
    image: "/assets/images/menu/Drywell_K.webp",
    torqueRange: "KB-SERIES-TORQUE",
    motorPower: "KB-SERIES-MOTOR",
    speedRange: "KB-SERIES-SPEED",
    categoryId: 403,
    type:"reducer",
    tableType : "tableStandard",

    link:"/urun/KB"
  },

  {
    itemId: 207,
    itemName: "H.B Serisi",
    image: "/assets/images/menu/HB.png",
    torqueRange: "HB-SERIES-TORQUE",
    ratioRange: "HB-SERIES-MOTOR",
    speedRange: "HB-SERIES-SPEED",
    categoryId: 403,
    type:"reducer",
    tableType : "tableHB",

    link:"/urun/HB"
  },

  {
    itemId: 208,
    itemName: "B.B Serisi",
    image: "/assets/images/menu/B.B.png",
   
    torqueRange: "BB-SERIES-TORQUE",
    ratioRange: "BB-SERIES-MOTOR",
    speedRange: "BB-SERIES-SPEED",
    categoryId: 403,
    type:"reducer",
    tableType : "tableBB",

    link:"/urun/BB"
  },

  {
    itemId: 209,
    itemName: "N.B Serisi",
    image: "/assets/images/menu/nrb.webp",
    torqueRange: "NB-SERIES-TORQUE",
    motorPower: "NB-SERIES-MOTOR",
    speedRange: "NB-SERIES-SPEED",
    categoryId: 403,
    type:"reducer",
    tableType : "tableStandard",

    link:"/urun/NB"
  },


  {
    itemId: 210,
    itemName: "BTY Serisi",
    image: "/assets/images/menu/BTY.png",
    torqueRange: "BTY-SERIES-TORQUE",
    ratioRange: "BTY-SERIES-MOTOR",
    speedRange: "BTY-SERIES-SPEED",
    categoryId: 404,
    type:"reducer",
    tableType : "tableBTY",

    link:"/urun/BTY"

  },

  {
    itemId: 211,
    itemName: "T Serisi",
    image: "/assets/images/menu/T.png",
    torqueRange: "T-SERIES-TORQUE",
    ratioRange: "T-SERIES-MOTOR",
    speedRange: "T-SERIES-SPEED",
    categoryId: 404,
    type:"reducer",
    tableType : "tableT",

    link:"/urun/T"

  },
  {
    itemId: 212,
    itemName: "PF Serisi",
    image: "/assets/images/menu/PF.webp",
    torqueRange: "PF-SERIES-TORQUE",
    ratioRange: "PF-SERIES-MOTOR",
    speedRange: "PF-SERIES-SPEED",
    categoryId: 405,
    type:"reducer",
    tableType : "tablePF",

    link:"/urun/PF"

  },

  {
    itemId: 213,
    itemName: "EV Serisi",
    image: "/assets/images/menu/EV.png",
    torqueRange: "EV-SERIES-TORQUE",
    motorPower: "EV-SERIES-MOTOR",
    speedRange: "EV-SERIES-SPEED",
    categoryId: 406,
    type:"reducer",
    tableType : "tableStandard",

    link:"/urun/EV"
  },
  {
    itemId: 214,
    itemName: "KR Serisi",
    image: "/assets/images/menu/KR.webp",
    torqueRange: "KR-SERIES-TORQUE",
    motorPower: "KR-SERIES-MOTOR",
    speedRange: "KR-SERIES-SPEED",
    categoryId: 406,
    type:"reducer",
    tableType : "tableStandard",

    link:"/urun/KR"
  },
  {
    itemId: 215,
    itemName: "CT Serisi",
    image: "/assets/images/menu/CT.png",
    ratioRange: "CT-SERIES-TORQUE",
    motorPower: "CT-SERIES-MOTOR",
    speedRange: "CT-SERIES-SPEED",
    categoryId: 406,
    type:"reducer",
    tableType : "tableCT",

    link:"/urun/CT"
  },



  {
    itemId: 216,
    itemName: "LRG Serisi",
    image: "/assets/images/menu/LRG.png",
    ratioRange: "LRG-SERIES-TORQUE",
    motorPower: "LRG-SERIES-MOTOR",
    inputSpeed: "LRG-SERIES-SPEED",
    categoryId: 407,
    type:"reducer",
    tableType : "tableLRG",

    link:"/urun/LRG"
  },

  {
    itemId: 217,
    itemName: "HRG Serisi",
    image: "/assets/images/menu/HRG.webp",
    ratioRange: "HRG-SERIES-TORQUE",
    motorPower: "HRG-SERIES-MOTOR",
    inputSpeed: "HRG-SERIES-SPEED",
    categoryId: 407,
    type:"reducer",
    tableType : "tableHRG",

    link:"/urun/HRG"
  },

  {
    itemId: 218,
    itemName: "PBW Serisi",
    image: "/assets/images/menu/P.BW.webp",
    torqueRange: "PBW-SERIES-TORQUE",
    motorPower: "PBW-SERIES-MOTOR",
    speedRange: "PBW-SERIES-SPEED",
    categoryId: 408,
    type:"reducer",
    tableType : "tablePBW",

    link:"/urun/PBW"
  },

  {
    itemId: 301,
    itemName: "YB1000",
    image: "/assets/images/menu/YB1000.png",
    powRange : "YB1000-TORQUE",
    inputVoltage : "YB1000-MOTOR",
    controlTec : "YB1000-SPEED",
    categoryId: 501,
    type:"automation",
    link:"/urun/yb1000"

  },

  {
    itemId: 302,
    itemName: "YA2000",
    image: "/assets/images/menu/YA2000.png",
    powRange : "YA2000-TORQUE",
    inputVoltage : "YA2000-MOTOR",
    controlTec : "YA2000-SPEED",
    categoryId: 501,
    type:"automation",
    link:"/urun/ya2000"


  },

  {
    itemId: 303,
    itemName: "YE8000",
    image: "/assets/images/menu/YE8000.png",
    powRange : "YE8000-TORQUE",
    inputVoltage : "YE8000-MOTOR",
    controlTec : "YE8000-SPEED",
    categoryId: 501,
    type:"automation",
    link:"/urun/ye8000"


  },

  {
    itemId: 304,
    itemName: "YI1000",
    image: "/assets/images/menu/YI1000.png",
    powRange : "YI1000-TORQUE",
    inputVoltage : "YI1000-MOTOR",
    controlTec : "YI1000-SPEED",
    categoryId: 501,
    type:"automation",
    link:"/urun/yi1000"


  },


  {
    itemId: 305,
    itemName: "YP65",
    image: "/assets/images/menu/YP65.png",
    powRange : "YP65-TORQUE",
    inputVoltage : "YP65-MOTOR",
    controlTec : "YP65-SPEED",
    categoryId: 501,
    type:"automation",
    link:"/urun/yp65"


},

{
  itemId: 306,
  itemName: "YP65-V",
  image: "/assets/images/menu/YP65.png",
  powRange : "YP65V-TORQUE",
  inputVoltage : "YP65V-MOTOR",
  controlTec : "YP65V-SPEED",
  categoryId: 501,
  type:"automation",
  link:"/urun/yp65-v"


},
/*
  {
    itemId: 306,
    itemName: "YDD",
    image: "/assets/images/menu/YDD.png",
    powRange : "YDD-TORQUE",
    inputVoltage : "YDD-MOTOR",
    controlTec : "YDD-SPEED",
    categoryId: 502,
    type:"automation",
    link:"/urun/ydd"


  },
*/
/*
  {
    itemId: 307,
    itemName: "YMP",
    image: "/assets/images/menu/ymp.png",
    powRange : "YMP-TORQUE",
    inputVoltage : "YMP-MOTOR",
    controlTec : "YMP-SPEED",
    categoryId: 502,
    type:"automation",
    link:"/urun/ymp"


  },
  */
  /*
  {
    itemId: 308,
    itemName: "YC3000",
    image: "/assets/images/menu/yc3000.png",
    powRange : "YC3000-TORQUE",
    inputVoltage : "YC3000-MOTOR",
    controlTec : "YC3000-SPEED",
    categoryId: 502,
    type:"automation",
    link:"/urun/yc3000"


  },
*/
  {
    itemId : 401,
    itemName : "AC-MOTOR",
    image : "/assets/images/menu/Motor.png",
    powRange : "AC-1-G-POW-AUT-V",
    speed : "AC-2-G-POW-AUT-V",
    efficiencyClass : "AC-3-G-POW-AUT-V",
    categoryId : 601,
    type: "motor",
    link:"/urun/ac"


  }

 
];
export default navbarItems;










