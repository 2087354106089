import React, { useState } from "react";
import navbarItems from './navbarItems'
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom'
import { useHistory } from "react-router-dom";

function NavbarItem(props) {
  const [navbarItem, setNavbarItem] = useState(props.categoryFirstItem);
  let history = useHistory();
  const onMouseEnter = (id) => {
    setNavbarItem(id);
  };


  const { t } = useTranslation();

  const typeReturnHead = (type,data) => {
    if(type === "automation"){
      return (
        <>
         <tr>
                <th className="navbar-th" scope="row">{t("G-POW-AUT")}</th>
                <th className="navbar-th" scope="row">{t("G-VOL-AUT")}</th>
                <th className="navbar-th" scope="row">{t("G-TEC-AUT")}</th>
        </tr>
        </>
      )
    }
    else if (type === "reducer"){
      if(data.tableType === "tableStandard"){
        return (
          <>
             <tr>
                              <th className="navbar-th" scope="row">{t("G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("G-MOT-P")}</th>
                              <th className="navbar-th" scope="row">{t("G-REV-R")}</th>
                          </tr>
          </>
        )
      }

      else if(data.tableType === "tableSectorel"){
        return (
          <>
             <tr>
                              <th className="navbar-th" scope="row">{t("G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("G-RATIO-RANGE")}</th>
                              <th className="navbar-th" scope="row">{t("G-REV-R")}</th>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableV"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("V-1-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("V-2-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("V-3-G-TOR-R")}</th>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableB"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("B-1-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("B-2-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("B-3-G-TOR-R")}</th>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableH"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("H-1-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("H-2-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("H-3-G-TOR-R")}</th>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableHE"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("HE-1-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("HE-2-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("HE-3-G-TOR-R")}</th>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableHB"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("HB-1-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("HB-2-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("HB-3-G-TOR-R")}</th>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableBTY"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("BTY-1-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("BTY-2-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("BTY-3-G-TOR-R")}</th>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableT"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("T-1-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("T-2-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("T-3-G-TOR-R")}</th>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tablePF"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("PF-1-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("PF-2-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("PF-3-G-TOR-R")}</th>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableCT"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("CT-1-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("CT-2-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("CT-3-G-TOR-R")}</th>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableLRG"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("LRG-1-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("LRG-2-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("LRG-3-G-TOR-R")}</th>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableHRG"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("HRG-1-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("HRG-2-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("HRG-3-G-TOR-R")}</th>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tablePBW"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("PBW-1-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("PBW-2-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("PBW-3-G-TOR-R")}</th>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tablePB"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("PB-1-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("PB-2-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("PB-3-G-TOR-R")}</th>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableBB"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("BB-1-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("BB-2-G-TOR-R")}</th>
                              <th className="navbar-th" scope="row">{t("BB-3-G-TOR-R")}</th>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableSpecial"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("G-RATIO-RANGE")}</th>
                              <th className="navbar-th" scope="row">{t("G-MOT-P")}</th>
                              <th className="navbar-th" scope="row">{t("G-REV-R")}</th>
                          </tr>
          </>
        )
      }


      else if (data.tableType === "tableSpecial-1"){
        return (
          <>
            <tr>
                              <th className="navbar-th" scope="row">{t("G-RATIO-RANGE")}</th>
                              <th className="navbar-th" scope="row">{t("G-MOT-P")}</th>
                              <th className="navbar-th" scope="row">{t("G-INPUTSPEED-TABLE")}</th>
                          </tr>
          </>
        )
      }


      

    }

    else if (type === "motor"){
      return (
        <>
         <tr>
                            <th className="navbar-th" scope="row">{t("AC-1-G-POW-AUT")}</th>
                            <th className="navbar-th" scope="row">{t("AC-2-G-POW-AUT")}</th>
                            <th className="navbar-th" scope="row">{t("AC-3-G-POW-AUT")}</th>
                        </tr>
        </>
      )
    }
   
  }


  const typeReturnBody = (type , data) => {

   
    if(type === "automation"){
      return (
        <>
         <tr>
                          <td className="navbar-td">{t(data.powRange)}</td>
                          <td className="navbar-td">{t(data.inputVoltage)}</td>
                          <td className="navbar-td">{t(data.controlTec)}</td>
                        </tr>
        </>
      )
    }
    else if (type === "reducer"){
      if(data.tableType === "tableStandard"){
        return (
          <>
              
              <tr>
                            <td className="navbar-td">{t(data.torqueRange)}</td>
                            <td className="navbar-td">{t(data.motorPower)}</td>
                            <td className="navbar-td">{t(data.speedRange)}</td>
              </tr>
          </>
        )
      }
      else if(data.tableType === "tableSectorel"){
        return (
          <>
              
              <tr>
                            <td className="navbar-td">{t(data.torqueRange)}</td>
                            <td className="navbar-td">{t(data.ratioRange)}</td>
                            <td className="navbar-td">{t(data.speedRange)}</td>
              </tr>
          </>
        )
      }
      else if (data.tableType === "tableV"){
        return (
          <>
            <tr>
                              <td className="navbar-td">{t(data.tonRange)}</td>
                              <td className="navbar-td">{t(data.ratioRange)}</td>
                              <td className="navbar-td">{t(data.motorPower)}</td>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableB"){
        return (
          <>
            <tr>
                              <td className="navbar-td">{t(data.torqueRange)}</td>
                              <td className="navbar-td">{t(data.ratioRange)}</td>
                              <td className="navbar-td">{t(data.speedRange)}</td>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableH"){
        return (
          <>
            <tr>
                              <td className="navbar-td">{t(data.torqueRange)}</td>
                              <td className="navbar-td">{t(data.ratioRange)}</td>
                              <td className="navbar-td">{t(data.speedRange)}</td>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableHE"){
        return (
          <>
            <tr>
                            <td className="navbar-td">{t(data.torqueRange)}</td>
                            <td className="navbar-td">{t(data.ratioRange)}</td>
                            <td className="navbar-td">{t(data.speedRange)}</td>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableHB"){
        return (
          <>
            <tr>
                              <td className="navbar-td">{t(data.torqueRange)}</td>
                              <td className="navbar-td">{t(data.ratioRange)}</td>
                              <td className="navbar-td">{t(data.speedRange)}</td>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableBTY"){
        return (
          <>
            <tr>
                              <td className="navbar-td">{t(data.torqueRange)}</td>
                              <td className="navbar-td">{t(data.ratioRange)}</td>
                              <td className="navbar-td">{t(data.speedRange)}</td>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableT"){
        return (
          <>
            <tr>
                              <td className="navbar-td">{t(data.torqueRange)}</td>
                              <td className="navbar-td">{t(data.ratioRange)}</td>
                              <td className="navbar-td">{t(data.speedRange)}</td>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tablePF"){
        return (
          <>
            <tr>
                            <td className="navbar-td">{t(data.torqueRange)}</td>
                            <td className="navbar-td">{t(data.ratioRange)}</td>
                            <td className="navbar-td">{t(data.speedRange)}</td>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableCT"){
        return (
          <>
            <tr>
                              <td className="navbar-td">{t(data.ratioRange)}</td>
                              <td className="navbar-td">{t(data.motorPower)}</td>
                              <td className="navbar-td">{t(data.speedRange)}</td>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableLRG"){
        return (
          <>
            <tr>
                            <td className="navbar-td">{t(data.ratioRange)}</td>
                            <td className="navbar-td">{t(data.motorPower)}</td>
                            <td className="navbar-td">{t(data.inputSpeed)}</td>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableHRG"){
        return (
          <>
            <tr>
                            <td className="navbar-td">{t(data.ratioRange)}</td>
                            <td className="navbar-td">{t(data.motorPower)}</td>
                            <td className="navbar-td">{t(data.inputSpeed)}</td>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tablePBW"){
        return (
          <>
            <tr>
                            <td className="navbar-td">{t(data.torqueRange)}</td>
                            <td className="navbar-td">{t(data.motorPower)}</td>
                            <td className="navbar-td">{t(data.speedRange)}</td>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tablePB"){
        return (
          <>
            <tr>
                            <td className="navbar-td">{t(data.torqueRange)}</td>
                            <td className="navbar-td">{t(data.motorPower)}</td>
                            <td className="navbar-td">{t(data.speedRange)}</td>
                          </tr>
          </>
        )
      }

      else if (data.tableType === "tableBB"){
        return (
          <>
            <tr>
                            <td className="navbar-td">{t(data.torqueRange)}</td>
                            <td className="navbar-td">{t(data.ratioRange)}</td>
                            <td className="navbar-td">{t(data.speedRange)}</td>
                          </tr>
          </>
        )
      }

      else if(data.tableType === "tableSpecial"){
        return (
          <>
              
              <tr>
                            <td className="navbar-td">{t(data.ratioRange)}</td>
                            <td className="navbar-td">{t(data.motorPower)}</td>
                            <td className="navbar-td">{t(data.speedRange)}</td>
              </tr>
          </>
        )
      }

      else if(data.tableType === "tableSpecial-1"){
        return (
          <>
              
              <tr>
                            <td className="navbar-td">{t(data.ratioRange)}</td>
                            <td className="navbar-td">{t(data.motorPower)}</td>
                            <td className="navbar-td">{t(data.inputSpeed)}</td>
              </tr>
          </>
        )
      }
    }

    else if (type === "motor"){
      return (
        <>
         <tr>
                          <td className="navbar-td">{t(data.powRange)}</td>
                          <td className="navbar-td">{t(data.speed)}</td>
                          <td className="navbar-td">{t(data.efficiencyClass)}</td>
            </tr>
        </>
      )
    }
  }
  const handleCloseNavBarMenu = (link) => {
        history.push(link);
        window.location.reload();
  }
  return (
    <>
      <div className="box-content-series">
        <div className="box-content-series-container">
        {navbarItems &&
          navbarItems.map((data, key) => {
            var reducerName = data.itemName.split("Serisi")[0].replace(" ","").replace(".","") +  "-SERIES-NAVBAR-TITLE";
            return data.categoryId === props.categoryId ? (
              <div key={key} className="box-content-series-item">
                <Link to={data.link} 
                  className={ navbarItem === data.itemId
                      ? "box-content-categorySeries-active"
                      : "box-content-categorySeries"
                  }
                  // style={{cursor:cursor}}
                  onMouseEnter={() => onMouseEnter(data.itemId)}
                >
                   <div  onClick={() => handleCloseNavBarMenu(data.link)} className="box-content-section-arrow-item">
                  <span> {data.type === "automation" || data.type === "motor" ? t(data.itemName) :  t(reducerName) }  </span>
                  {navbarItem === data.itemId && <img alt="arrow"  src="/assets/images/bg/Arrow.png" class="nav-arrow-item"></img>}
                  </div>
                
               
                </Link>
                {navbarItem === data.itemId ? (
                  <div className="box-content-productInfo">
                    <img src={data.image} alt="reduktor"/>
                    <table className="navbar-table">
                      <thead>
                        {typeReturnHead(data.type,data)}
                      
                      </thead>
                      <tbody>
                         {typeReturnBody(data.type,data)}
                       
                       
                      </tbody>
                    </table>
                  </div>
                ) : null}
              </div>
            ) : null;
          })}
      </div>
      </div>
    </>
  );
}

export default NavbarItem;
