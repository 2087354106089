import React, { useEffect, useState } from "react";
import NavbarData from "./NavbarData";
import { Link, useLocation } from "react-router-dom";
import NavbarSubMenu from "./NavbarSubMenu/NavbarSubMenu";
import NavbarMenu from "./NavbarSubMenu/NavbarProduct/NavbarProduct";
import "../Navbar/NavbarMobile/NavbarMobile.css";
import { useMediaPredicate } from "react-media-hook";
import {useSpring, animated} from 'react-spring'
import useLanguage from '../../../language/useLanguage'
import "../../../language/i18n"
import i18n from "../../../language/i18n"
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'


function NavbarWeb() {

  const deneme = "11";
  const languages = [
    {name:"TR", code: "tr-TR",image: 'https://www.countryflags.io/tr/shiny/16.png' },
    {name:"EN", code: "en-US" , image: 'https://www.countryflags.io/gb/shiny/16.png' },
    //{name:"CODE", code: "code-CODE", image: 'https://www.countryflags.io/uz/shiny/16.png' },
    {name:"DE", code: "de-DE", image: 'https://www.countryflags.io/de/shiny/16.png' },
    {name:"FR", code: "fr-FR", image: 'https://www.countryflags.io/de/shiny/16.png' },
    //{name:"RU", code: "ru-RU", image: 'https://www.countryflags.io/ru/shiny/16.png' },
  ]

  const isMobile = useMediaPredicate("(max-width: 1024px)");
  const [navbarSelected, setNavbarSelected] = useState();
  const [headerMinimized, setHeaderMinimized] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [pathName, setPathName] = useState();
  const [bg,setBg] = useState("white");
  const { pathname } = useLocation();
  const location= useLocation();
  const [selectedOption, setSelectedOption] = useState( localStorage.getItem('language'));



  const [route, setRoute] = useState({ //--> It can be replaced with useRef or localStorage
    to: location.pathname,
    from: location.pathname //--> previous pathname
  });


  // const { language, setLanguage  } = useLanguage();

  const changeLanguage = (lng) => {
    setSelectedOption(lng)
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    window.location.reload();
  
  }


  
  useEffect(()=> {
    setRoute((prev)=> ({to: location.pathname, from: prev.to}) )
  }, [location]);


  useEffect(() => {
    if(localStorage.getItem("language") === null){
      localStorage.setItem('language','tr-TR')
    }
    i18n.changeLanguage(localStorage.getItem("language"));
 
  },[localStorage.getItem("language")])

 

  const selectedLanguage = i18n.language;
  const { t } = useTranslation();
  const props = useSpring({opacity: 1, from: {opacity: 0}})
  useEffect(() => {
    setPathName(pathname);
  }, [pathname]);

  const onMouseEnter = (id) => {
    setNavbarSelected(id);
   
  };
  const onMouseLeave = () => {
    setNavbarSelected();
  };
  const changeHeaderClass = () => {
    if (window.scrollY !== 0) {
      setHeaderMinimized(true);
    } else setHeaderMinimized(false);
  };
  window.addEventListener("scroll", changeHeaderClass);

  var result;

  if(pathName === "/"){
    if(!headerMinimized)
      result = "transparentHomePage";
    else
      result = "colorHomePage";
  }
  else  
    result = "colorOtherPage";


  const handleClickStyle = (e) => {
    
  }
  return (
    <header
      style={
        pathName === "/"
          ? { backgroundColor: "transparent", boxShadow: "none" }
          : {
              backgroundColor: "#18222a",
              boxShadow:
                "rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px",
            }
      }
      id="masthead"
      className={
        headerMinimized
          ? "site-header clearfix minimized"
          : "site-header clearfix"
      }
    >
     <>
      <div
        className={`header-main ${result} clearfix  ${isMobile ? "custom-nav-mobile" : ''}`}
      >
        <div className={` ${isMobile ? "custom-container" : "container"}`} style={{width : isMobile ? '' : '1200px'}}>
          <div
            className="row "
            style={{
              display: "flex",
              maxWidth :  "1500px",
              alignItems: "center",
            }}
          >
            {!isMobile && (
              <>
                <div className="site-logo col-md-3 col-sm-6 col-xs-6">
                  <Link to="/" className="logo">
                    <img
                      src={
                        headerMinimized
                          ? "/assets/images/logo/logo2.webp"
                          : "/assets/images/logo/logo.webp"
                      }
                      alt="YılmazRedüktör"
                      className="logo-dark show-logo"
                    />
                  </Link>
                </div>
                <div className="site-menu col-md-9 col-sm-6 col-xs-6 custom-col-menu">
                  <nav id="site-navigation" className="main-nav primary-nav nav">
                    <ul className="menu">
                      {NavbarData.map((data, key) => {
                        return (
                          <li
                            key = {key}
                            onMouseEnter={() => onMouseEnter(data.navbarId)}
                            onMouseLeave={onMouseLeave}
                          >
                            <Link to={data.path}>{t(data.name)}</Link>
                            {navbarSelected === 0 ? null : navbarSelected ===
                                1 && data.navbarId === 1 ? (
                            <NavbarMenu  navbarSelected={navbarSelected} />
                            ) : (
                              <ul className="sub-menu">
                              <NavbarSubMenu navbarId={data.navbarId} /> 
                              </ul>
                            )}
                          </li>
                        );
                      })}
                      <li>
                        {/*<label for="languagexxx"></label>*/}
                      <select style={{border:'none' , backgroundColor:'transparent' , color:'white'}} id="languagexxx" aria-label="languagexxx" value={selectedOption}  onChange={e => changeLanguage(e.target.value)}>
{
                     
                     languages.map(( language ) => {
                       return (
                         <option style={{background:'var(--primary-dark)' , fontSize:'14px'}} value={language.code} key={language.code}>{language.name}</option>
                       );
                     })  
                     }
</select>
                      </li>
                      
                    </ul>
                  
                     {/* <div onClick={() => handleClickStyle()} style={{display:'inline-flex' , flexDirection:'column' , overflow:'hidden' , height:'30px' , alignItems:'center'}}> */}
                      {/* {
                     
      languages.map(( language ) => {
        return (
          <option value={language.code} key={language.code} 
className={selectedLanguage === language.code ? "active" : ""} 
onClick={ () => changeLanguage( language.code ) }>{language.name} <img src={language.image}/></option>
        );
      })  
      } */}

{/* </div> */}








                  </nav>
                </div>{" "}
              </>
            )}

            {isMobile && (
              <div className="site-logo-mobile col-sm-12 col-xs-12">
                <div style={{height : isChecked ? '100vh' : 'inherit'}} className="nav">
                  <input
                    type="checkbox"
                    id="menu"
                    onChange={(e) => {
                      e.target.checked === true
                        ? setIsChecked(true)
                        : setIsChecked(false);
                    }}
                  />
                  <label
                    className={`${
                      isChecked
                        ? "nav-background-mobile-on"
                        : "nav-background-mobile-off"
                    }`}
                    id="nav-icon"
                    htmlFor="menu"
                  >
                 

                 <Link className="navbar-logo-location"  to="/">
                     <img
                     alt="Logo"
                      className={`${
                        isChecked ? "nav-logo-mobile-on" : "nav-logo-mobile-off"
                      }`}
                       src= {isChecked ? "/assets/images/logo/logo.webp" : "/assets/images/logo/logo.webp"} 

                    />{" "}
                     </Link>

                             

                {isChecked  &&       <div className="item-icon">
                <select style={{border:'none' , backgroundColor:'transparent' , color:'white',fontSize:'18px'}} value={selectedOption}  onChange={e => changeLanguage(e.target.value)}>
{
                     
                     languages.map(( language ) => {
                       return (
                         <option style={{background:'var(--primary-dark)' , fontSize:'14px'}} value={language.code} key={language.code}>{language.name}</option>
                       );
                     })  
                     }
</select>
                      {/* <span><i className="fas fa-phone-alt"></i></span> */}
                     
                    </div>}
           
                
                     {isChecked ? (  <div
                      className="toggle-menu-mobile-on">
                      <span></span>
                     
                    </div>) :  <div
                      className="toggle-menu-mobile-off">
                        <span></span>
                    </div> }
                  


                  </label>

                  <animated.div style={{...props , display : isChecked ? 'block' : 'none'}} className="multi-level">
                    <div className="item">
                      <input type="checkbox" id="Urunler" />
                      <img
                        alt="arrow"
                        src="/assets/images/bg/Arrow.png"
                        className="nav-arrow"
                      />{" "}
                      <label htmlFor="Urunler">{t("NB-PR")}</label>
                      <ul>
                        <li>
                          <div className="sub-item">
                            <input type="checkbox" id="StandartReduktorler" />
                            <img alt="arrow"
                              src="/assets/images/bg/Arrow.png"
                              className="nav-arrow"
                            />
                            <label htmlFor="StandartReduktorler">{t("NB-PR-MN-SR-T")}</label>
                            <ul>
                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="AyakBaglantiliHeliselDisli"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="AyakBaglantiliHeliselDisli">
                                    {t("NB-PR-MN-SR-SUB-1")}
                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  }  >
                                      <Link to="/urun/M">M {t("G-Series")}</Link>
                                    </li>
                                   
                                  </ul>
                                </div>
                              </li>


                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="FlansBaglantiliHeliselDisli"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="FlansBaglantiliHeliselDisli">
                                    {t("NB-PR-MN-SR-SUB-2")}
                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/N">N {t("G-Series")}</Link>
                                    </li>
                                   
                                  </ul>
                                </div>
                              </li>



                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="ParalelMilliHeliselDisli"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="ParalelMilliHeliselDisli">
                                   {t("NB-PR-MN-SR-SUB-3")}
                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/D">D {t("G-Series")}</Link>
                                    </li>
                                   
                                  </ul>
                                </div>
                              </li>


                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="KonikHeliselDisli"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="KonikHeliselDisli">
                                    {t("NB-PR-MN-SR-SUB-4")}
                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/K">K {t("G-Series")}</Link>
                                    </li>
                                   
                                  </ul>
                                </div>
                              </li>




                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="SonsuzVidalı"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="SonsuzVidalı">
                                    {t("NB-PR-MN-SR-SUB-5")}
                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/E">E {t("G-Series")}</Link>
                                    </li>
                                   
                                  </ul>
                                </div>
                              </li>



                            </ul>



                          </div>
                        </li>

                        <li>
                          <div className="sub-item">
                            <input type="checkbox" id="AgirReduktorler" />
                            <img alt="arrow"
                              src="/assets/images/bg/Arrow.png"
                              className="nav-arrow"
                            />
                            <label htmlFor="AgirReduktorler">{t("NB-PR-MN-HVY-T")}</label>
                            <ul>
                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="PlanetDisliFlansBaglantili"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="PlanetDisliFlansBaglantili">
                                    {t("NB-PR-MN-HVY-SUB-1")}
                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/PL">P.L {t("G-Series")}</Link>
                                    </li>

                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/PK">P.K {t("G-Series")}</Link>
                                    </li>
                                   
                                  </ul>
                                </div>
                              </li>


                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="PlanetDisliAyakBaglantili"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="PlanetDisliAyakBaglantili">
                                    {t("NB-PR-MN-HVY-SUB-2")}
                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/RL">R.L {t("G-Series")}</Link>
                                    </li>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/RK">R.K {t("G-Series")}</Link>
                                    </li>
                                   
                                  </ul>
                                </div>
                              </li>



                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="HeliselDisliYatikTip"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="HeliselDisliYatikTip">
                                    {t("NB-PR-MN-HVY-SUB-3")}
                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/H">H {t("G-Series")}</Link>
                                    </li>
                                   
                                  </ul>
                                </div>
                              </li>


                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="KonikHelisekDisliYatikTip"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="KonikHelisekDisliYatikTip">
                                    {t("NB-PR-MN-HVY-SUB-4")}
                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/B">B {t("G-Series")}</Link>
                                    </li>
                                   
                                  </ul>
                                </div>
                              </li>







                            </ul>



                          </div>
                        </li>
                      



                        <li>
                          <div className="sub-item">
                            <input type="checkbox" id="SektorelReduktorler" />
                            <img alt="arrow"
                              src="/assets/images/bg/Arrow.png"
                              className="nav-arrow"
                            />
                            <label htmlFor="SektorelReduktorler">{t("NB-PR-MN-SEC-T")}</label>
                            <ul>
                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="VincTamburu"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="VincTamburu">
                                    {t("NB-PR-MN-SEC-SUB-1")}
                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/V">V {t("G-Series")}</Link>
                                    </li>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/PB">P.B {t("G-Series")}</Link>
                                    </li>
                                  </ul>
                                </div>
                              </li>


                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="Ekstruder"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="Ekstruder">
                                  {t("NB-PR-MN-SEC-SUB-2")}

                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/HE">H.E {t("G-Series")}</Link>
                                    </li>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/DE">D.E {t("G-Series")}</Link>
                                    </li>

                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/KE">K.E {t("G-Series")}</Link>
                                    </li>
                                   
                                  </ul>
                                </div>
                              </li>



                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="Karistirici"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="Karistirici">
                                  {t("NB-PR-MN-SEC-SUB-3")}

                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/DB">D.B {t("G-Series")}</Link>
                                    </li>
                                   
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/KB">K.B {t("G-Series")}</Link>
                                    </li>

                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/HB">H.B {t("G-Series")}</Link>
                                    </li>

                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/BB">B.B {t("G-Series")}</Link>
                                    </li>
                                    <li>
                                      <Link to="/urun/NB">N.B {t("G-Series")}</Link>
                                    </li>
                                  </ul>
                                </div>
                              </li>


                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="ElevatorKonveyor"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="ElevatorKonveyor">
                                  {t("NB-PR-MN-SEC-SUB-4")}

                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/BTY">BTY {t("G-Series")}</Link>
                                    </li>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/T">T {t("G-Series")}</Link>
                                    </li>
                                   
                                  </ul>
                                </div>
                              </li>



                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="BetonMikseri"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="BetonMikseri">
                                  {t("NB-PR-MN-SEC-SUB-5")}

                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/PF">PF {t("G-Series")}</Link>
                                    </li>
                                    
                                   
                                  </ul>
                                </div>
                              </li>


                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="Testere"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="Testere">
                                  {t("NB-PR-MN-SEC-SUB-6")}

                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/KR">KR {t("G-Series")}</Link>
                                    </li>

                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/CT">CT {t("G-Series")}</Link>
                                    </li> 
                                    
                                   
                                  </ul>
                                </div>
                              </li>


                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="RayliSistemler"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="RayliSistemler">
                                  {t("NB-PR-MN-SEC-SUB-7")}

                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/LRG">LRG {t("G-Series")}</Link>
                                    </li>

                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/HRG">HRG {t("G-Series")}</Link>
                                    </li>
                                    
                                   
                                  </ul>
                                </div>
                              </li>


                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="RuzgarTurbini"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="RuzgarTurbini">
                                  {t("NB-PR-MN-SEC-SUB-8")}

                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/PBW">P.BW {t("G-Series")}</Link>
                                    </li>

                                    
                                   
                                  </ul>
                                </div>
                              </li>


                            </ul>



                            



                          </div>
                        </li>


                        <li>
                          <div className="sub-item">
                            <input type="checkbox" id="OtomasyonUrunleri" />
                            <img alt="arrow"
                              src="/assets/images/bg/Arrow.png"
                              className="nav-arrow"
                            />
                            <label htmlFor="OtomasyonUrunleri">{t("NB-PR-MN-AUT-T")}</label>
                            <ul>
                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="TemelSeri"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="TemelSeri">
                                   {t("NB-PR-MN-AUT-SUB-1")}
                                  </label>
                                  <ul>
                                  
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/yb1000">YB1000</Link>
                                    </li>

                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/ya2000">YA2000</Link>
                                    </li>

                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/ye8000">YE8000</Link>
                                    </li>

                                      <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/yi1000">YI1000</Link>
                                    </li>

                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/yp65">YP65</Link>
                                    </li>

                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/yp65-V">YP65-V</Link>
                                    </li>
                                   
                                  </ul>
                                </div>
                              </li>


                              {/*<li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="GelismisSeri"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="GelismisSeri">
                                  {t("NB-PR-MN-AUT-SUB-2")}
                                  </label>
                                  <ul>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/ydd">YDD</Link>
                                    </li>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/ymp">YMP</Link>
                                    </li>
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/yc3000">YC 3000</Link>
                                    </li>
                                   
                                  </ul>
                                </div>
                              </li>                     */}






                            </ul>



                          </div>
                        </li>


                        
                        <li>
                          <div className="sub-item">
                            <input type="checkbox" id="MotorUrunleri" />
                            <img alt="arrow"
                              src="/assets/images/bg/Arrow.png"
                              className="nav-arrow"
                            />
                            <label htmlFor="MotorUrunleri">{t("NB-PR-MN-MOTOR")}</label>
                            <ul>
                              <li>
                                <div className="sub-item-x">
                                  <input
                                    type="checkbox"
                                    id="ElektrikMotor"
                                  />
                                  <img
                                  alt="arrow"
                                    src="/assets/images/bg/Arrow.png"
                                    className="nav-arrow"
                                  />
                                  <label htmlFor="ElektrikMotor">
                                   {t("ELK-MOTOR-ELECTRIC")}
                                  </label>
                                  <ul>
                                  
                                    <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                                      <Link to="/urun/ac">AC MOTOR</Link>
                                    </li>

                                 
                                   
                                  </ul>
                                </div>
                              </li>








                            </ul>



                          </div>
                        </li>

                      </ul>
                    </div>

                    <div className="item">
                      <input type="checkbox" id="Cozumler" />
                      <img
                       alt="arrow"
                        src="/assets/images/bg/Arrow.png"
                        className="nav-arrow"
                      />
                      <div onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                         <Link to="cozumler"><label htmlFor="Cozumler">{t("NB-SL")}</label></Link>
                      </div>
                     

                   
                    </div>

                    <div className="item">
                      <input type="checkbox" id="Satis ve Servisler" />
                      <img
                       alt="arrow"
                        src="/assets/images/bg/Arrow.png"
                        className="nav-arrow"
                      />
                      <div onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } > <Link to="/satis-ve-servisler"><label htmlFor="Satıs ve Hizmetler">{t("NB-SS")}</label></Link></div>
                     

                   
                    </div>



                    <div className="item">
                      <input type="checkbox" id="Dokuman" />
                      <img
                       alt="arrow"
                        src="/assets/images/bg/Arrow.png"
                        className="nav-arrow"
                      />
                      <div onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                          <Link to="/dokuman"><label htmlFor="Dokuman">{t("NB-DC")}</label></Link>
                      </div>
                    

                   
                    </div>


                    <div className="item">
                      <input type="checkbox" id="Haberler" />
                      <img
                       alt="arrow"
                        src="/assets/images/bg/Arrow.png"
                        className="nav-arrow"
                      />
                      <div onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >  <Link to="/haberler"><label htmlFor="Haberler">{t("NB-NP")}</label></Link></div>
                    

                   
                    </div>



                    <div className="item">
                      <input type="checkbox" id="Sirket" />
                      <img
                       alt="arrow"

                        src="/assets/images/bg/Arrow.png"
                        className="nav-arrow"
                      />
                      <label htmlFor="Sirket">{t("NB-CP")}</label>

                      <ul>
                        <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                          <Link to="/tarihce">{t("NB-CP-SUB-1")}</Link>
                        </li>
                        <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                          <Link to="/misyonumuz">{t("NB-CP-SUB-2")}</Link>
                        </li>
                        <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                          <Link to="/sirketlerimiz">{t("NB-CP-SUB-3")}</Link>
                        </li>
                        <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                          <Link to="/politikalarımız">{t("NB-CP-SUB-4")}</Link>
                        </li>
                      </ul>
                    </div>


                    <div className="item">
                      <input type="checkbox" id="Videolar" />
                      <img
                       alt="arrow"
                        src="/assets/images/bg/Arrow.png"
                        className="nav-arrow"
                      />
                      <div onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >  <Link to="/videolar"><label htmlFor="Videolar">{t("NEWS-P-V-T")}</label></Link></div>
                    

                   
                    </div>


                    <div className="item">
                      <input type="checkbox" id="İletisim" />
                      <img
                       alt="arrow"

                        src="/assets/images/bg/Arrow.png"
                        className="nav-arrow"
                      />
                      <label htmlFor="İletisim">{t("NB-CT")}</label>

                      <ul>
                      <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                          <Link to="/musteri-hizmetleri">{t("SCP-CST-SER")}</Link>
                        </li>
                        <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                          <Link to="/satis-ve-servisler">{t("NV-DEALERS")}</Link>
                        </li>
                        <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                          <Link to="/is-ve-staj-basvuru">{t("NB-CT-SUB-1")}</Link>
                        </li>
                        <li onClick={() => isChecked === true ? setIsChecked(false) : setIsChecked(true)  } >
                          <Link to="/oneri-sikayet-ve-istek">{t("NB-CT-SUB-2")}</Link>
                        </li>
                       
                      </ul>
                    </div>
                      
                 
                  </animated.div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      </>

     
    </header>
  );
}

export default NavbarWeb;
